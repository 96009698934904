import React from 'react';
import { H2 } from '../../primitives/appTitle';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';
import { ShTimeline, ShTimestamp } from '@shoootin/components-web';

import { FrontPhotographerMessages } from './photographerPageTranslations';
import { ShText } from '@shoootin/translations';
import { AppSection } from '../../primitives/appSection';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';

export const PhotographTimeline: ShTimestamp[] = [
  {
    caption: FrontPhotographerMessages.timeline_1,
  },
  {
    caption: FrontPhotographerMessages.timeline_2,
  },
  {
    caption: FrontPhotographerMessages.timeline_3,
  },
];

const PhotographerTimeline = () => (
  <AppSection
    header={
      <H2>
        <ShText message={FrontPhotographerMessages.timelineTitle} />{' '}
      </H2>
    }
    css={{
      backgroundColor: ShColors.base,
      color: ShColors.white,
    }}
  >
    <ShTimeline
      timestamps={PhotographTimeline}
      mention={
        <p>
          <ShText message={FrontPhotographerMessages.timelineMention1} />{' '}
          <span>
            <ShText message={FrontPhotographerMessages.timelineMention2} />
            {/*<AppInternalLink to={AppInternalPaths.faq} className="invert">*/}
            {/*TODO reactivate when ready*/}
            {/*F.A.Q*/}
            {/*</AppInternalLink>*/}
          </span>
        </p>
      }
    />
  </AppSection>
);

export default PhotographerTimeline;
