import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import './frontPhotograph.less';
import { H1 } from '../../primitives/appTitle';
import HeroTitle from '../../components/hero/heroTitle';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';
import PhotographerHighlights from './photographerPageHighlightsSection';
import PhotographerTimeline from './photographerPageTimeline';
import PhotographerPageTestimonials from './photographerPageTestimonials';
import PhotographerPageIntro from './photographerPageIntro';
import PhotographerPageBanner from './photographerPageBanner';
import PhotographerPageApp from './photographerPageApp';
import PhotographerPageJoinSection from './photographerPageJoinSection';
import { graphql } from 'gatsby';
import { getFluidImage } from '../../appGatsbyImageUtils';
import { PhotographerPagePhotoQuery } from '../../appGraphQLTypes';

const PhotographerPage = ({ data }: { data: PhotographerPagePhotoQuery }) => {
  const heroImage = getFluidImage(data.heroImage!);
  const expertImage = getFluidImage(data.expertImage!, 'expertImage');
  const appImage = getFluidImage(data.appImage!, 'appImage');
  return (
    <MainLayout
      className="page-photograph"
      showContact={false}
      pageName="photographer"
    >
      <HeroTitle imgSrc={heroImage}>
        <H1 modifier="hero-title">
          <ShText message={FrontPhotographerMessages.pageTitle} />
        </H1>
      </HeroTitle>

      <PhotographerPageIntro image={expertImage} />

      <PhotographerHighlights />

      <PhotographerTimeline />

      <PhotographerPageTestimonials />

      <PhotographerPageBanner />

      <PhotographerPageApp image={appImage} />

      <PhotographerPageJoinSection />
    </MainLayout>
  );
};

export default PhotographerPage;

export const query = graphql`
  query PhotographerPagePhoto {
    heroImage: file(relativePath: { eq: "photographer_header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
    expertImage: file(relativePath: { eq: "photographer_expert.jpg" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 540, quality: 85, srcSetBreakpoints: [350, 450, 540]) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    appImage: file(relativePath: { eq: "photographer_app.png" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 500, quality: 85, srcSetBreakpoints: [350, 450, 500]) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
