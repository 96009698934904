import React from 'react';

import { H3 } from '../../primitives/appTitle';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';
import AppSection from '../../primitives/appSection';
import Badges from '../../components/badges';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

const PhotographerPageApp = ({ image }: { image: AppGatsbyImageFluid }) => (
  <AppSection className="darker">
    <div className="photograph-app">
      <div className="photograph-app__caption">
        <H3>
          <ShText message={FrontPhotographerMessages.appTitle} />
        </H3>
        <p>
          <ShText message={FrontPhotographerMessages.appP1} />
        </p>
        <Badges />
      </div>
      <div className="photograph-app__visual">
        <div style={{ position: 'relative' }}>
          <AppGatsbyImage fluid={image} style={{ position: 'relative' }} />
        </div>
      </div>
    </div>
  </AppSection>
);

export default PhotographerPageApp;
