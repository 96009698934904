import React from 'react';

import { H3 } from '../../primitives/appTitle';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';
import BannerPhoto from '../../components/banner/bannerPhoto';
import AppImage from '../../primitives/appImage';

const PhotographerPageBanner = () => (
  <BannerPhoto imageSrc={require('images/background/photograph-banner.jpg')}>
    <AppImage
      className="banner__image-mobile"
      src={require('images/background/photograph-banner-mobile.jpg')}
      lazy
    />
    <div className="banner__wrapper">
      <H3>
        <ShText message={FrontPhotographerMessages.bannerTitle} />
      </H3>
      <p css={{ whiteSpace: 'pre-wrap' }}>
        <ShText message={FrontPhotographerMessages.bannerP1} />
      </p>
    </div>
  </BannerPhoto>
);

export default PhotographerPageBanner;
