import React from 'react';
import Highlights, {
  getHLMessages,
  Highlight,
  HLNumber,
} from '../../components/highlights';
import AppSection from '../../primitives/appSection';
import { FrontPhotographerMessages } from './photographerPageTranslations';

export const getHLMessagesPhotographer = (panelNumber: HLNumber) => {
  return getHLMessages(panelNumber, FrontPhotographerMessages);
};

export const highlights: Highlight[] = [
  {
    title: getHLMessagesPhotographer(1).title,
    p1: getHLMessagesPhotographer(1).p1,
    svg: true,
    svgColor: {
      stopColor1: '#7fbb6e',
      stopColor2: '#5472FF',
    },
  },
  {
    title: getHLMessagesPhotographer(2).title,
    p1: getHLMessagesPhotographer(2).p1,
    svg: true,
    svgColor: {
      stopColor1: '#5472FF',
      stopColor2: '#9f4ecf',
    },
  },
  {
    title: getHLMessagesPhotographer(3).title,
    p1: getHLMessagesPhotographer(3).p1,
    svg: true,
    svgColor: {
      stopColor1: '#5472FF',
      stopColor2: '#18b0b6',
    },
  },
];

export const PhotographerHighlights = () => {
  return (
    <AppSection className="photograph-highlights">
      <Highlights highlights={highlights} />
    </AppSection>
  );
};

export default PhotographerHighlights;
