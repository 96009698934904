import React, { ReactNode } from 'react';
import classnames from 'classnames';

const BannerPhoto = ({
  imageSrc,
  children,
}: {
  imageSrc?: string;
  children: ReactNode;
}) => (
  <div
    className="banner banner--photo"
    style={{ backgroundImage: `url(${imageSrc})` }}
  >
    <div className="banner-content">{children}</div>
  </div>
);

export default BannerPhoto;
