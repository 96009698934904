import React from 'react';

import { H2 } from '../../primitives/appTitle';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';
import AppSection from '../../primitives/appSection';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';
import PatternSvg from '../../components/svg/patternSvg';

const PhotographerPageJoinSection = () => (
  <AppSection
    className="photograph-join"
    header={
      <div>
        <H2>
          <ShText message={FrontPhotographerMessages.joinTitle} />
        </H2>
        <p>
          <ShText message={FrontPhotographerMessages.joinP1} />
        </p>
        <AppInternalLink to={AppInternalPaths.join} className="btn large">
          <ShText message={FrontPhotographerMessages.joinLink} />
          <PatternSvg />
        </AppInternalLink>
      </div>
    }
  />
);

export default PhotographerPageJoinSection;
