import React from 'react';

const ChevronNextSvg = (props: any) => (
  <svg
    className="chevron-next"
    viewBox="0 0 28 28"
    height={28}
    width={28}
    fill="none"
    {...props}
  >
    <polyline points="8,1.75 20.25,14 8,26.25 " />
  </svg>
);

export default ChevronNextSvg;
