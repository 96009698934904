import React from 'react';

import { H2 } from '../../primitives/appTitle';
import AppSection from '../../primitives/appSection';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../../primitives/appImage';

const PhotographerPageIntro = ({ image }: { image: AppGatsbyImageFluid }) => (
  <AppSection className="photograph-intro">
    <div className="photograph-intro__content">
      <div className="photograph-intro__cover" data-parallax="0.4">
        <AppGatsbyImage fluid={image} />
      </div>
      <div className="photograph-intro__text">
        <H2>
          <ShText message={FrontPhotographerMessages.introTitle} />
        </H2>
        <p>
          <ShText message={FrontPhotographerMessages.introp1} />
        </p>
        <div className="photograph-intro__image-mobile">
          <AppGatsbyImage fluid={image} />
        </div>
        <p>
          <ShText message={FrontPhotographerMessages.introp2} />
        </p>
        <AppInternalLink to={AppInternalPaths.join} className="large">
          <ShText message={FrontPhotographerMessages.introJoinUs} />
        </AppInternalLink>
      </div>
    </div>
  </AppSection>
);

export default PhotographerPageIntro;
