import React from 'react';

import { H2 } from '../../primitives/appTitle';
import AppSection from '../../primitives/appSection';
import SliderPhotographs from '../../components/sliderPhotographs';
import { ShText } from '@shoootin/translations';
import { FrontPhotographerMessages } from './photographerPageTranslations';

const PhotographerPageTestimonials = () => (
  <AppSection
    className="darker"
    header={
      <div>
        <H2>
          <ShText message={FrontPhotographerMessages.testimonialsTitle} />
        </H2>
        <p>
          <ShText message={FrontPhotographerMessages.testimonialsp1} />
        </p>
      </div>
    }
  >
    <SliderPhotographs />
  </AppSection>
);

export default PhotographerPageTestimonials;
